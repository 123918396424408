import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import slider1 from '../Images/Slider/1.png'
import slider2 from '../Images/Slider/2.png'
import slider3 from '../Images/Slider/3.png'
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../Db/firebase';
import {Link} from 'react-router-dom'

const Slider = () => {
    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Course: "",
        Mobile: "",
        Message: ""

    });
    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Course, Mobile, Message } = userData;
        if (Name && Email && Course && Mobile && Message) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                alert('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    alert("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    alert("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                const docRef = await addDoc(collection(db, "Get_In_Touch_Form"), {
                    Name: Name,
                    Email: Email,
                    Course: Course,
                    Mobile: Mobile,
                    Message: Message
                });


                emailjs.sendForm('service_9jjce1y', 'template_pzhmwzp', form.current, 'fS_Fn-Qu2umrSHbxV')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                alert('Message Sent Successfully');

                setUserData({
                    Name: "",
                    Email: "",
                    Course: "",
                    Mobile: "",
                    Message: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                alert("Error adding document")
            }
        }
        else {
            alert('Please fill the data');
        }
    };

    return (
        <div>
            {/* Slider */}
            <div className="carousel">
                <div class="carousel-wrapper">
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false}>
                        <div className='carousel-body'>
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510113/Bliss%20School%20Of%20Music/Slider/1_poxzmq.png" />
                            <div class="carousel-caption">
                                <h5>Play the Keys to Your Dreams with Our Piano Excellence!</h5>
                               <Link to={"/courses/#pricing"}>Start learning</Link>
                            </div>
                        </div>
                        <div className='carousel-body'>
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510113/Bliss%20School%20Of%20Music/Slider/2_dd0u64.png" />
                            <div class="carousel-caption">
                                <h5>Guiding Your Musical Journey with Skill, Passion, and Precision!</h5>
                               <Link to={"/courses/#pricing"}>Start learning</Link>
                            </div>
                        </div>
                        <div className='carousel-body'>
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510113/Bliss%20School%20Of%20Music/Slider/3_akxd3c.png" />
                            <div class="carousel-caption">
                                <h5>Where Melodies Take Flight and Aspirations Find Their Rhythm.</h5>
                               <Link to={"/courses/#pricing"}>Start learning</Link>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <form ref={form} className="carousel-form" id="get_in_touch" method="POST" >
                    <h4>Get In Touch</h4>
                    <div className="carousel-form-body">
                        <input
                            type="text"
                            name="Name"
                            id="name"
                            placeholder='Your Name'
                            value={userData.Name}
                            onChange={postUserData}
                            required />
                        <input type="text" name="Mobile"
                            id="Mobile"
                            placeholder='Mobile Number'
                            value={userData.Mobile}
                            onChange={postUserData}
                            required />
                    </div>
                    <div className="carousel-form-body">
                        <input
                            type="email"
                            name="Email"
                            id="email"
                            placeholder='Email'
                            value={userData.Email}
                            onChange={postUserData}
                            required />

                        <select aria-label="Default select example"
                            value={userData.Course}
                            onChange={postUserData}
                            className="form-select"
                            name='Course'>
                            <option value="default" defaultValue='Select Course'>Select Course</option>
                            <option value="Piano">Piano</option>
                            <option value="Classical Guitar">Classical Guitar</option>
                            <option value="Acoustic Guitar">Acoustic Guitar</option>
                            <option value="Drums">Drums</option>
                            <option value="Keyboard">Keyboard</option>
                            <option value="Vocal">Vocal</option>
                        </select>
                    </div>
                    <div className="carousel-form-body">
                        <textarea
                            id="message"
                            cols="30"
                            rows="7"
                            placeholder='Your Message'
                            name="Message"
                            value={userData.Message}
                            onChange={postUserData}
                            required></textarea>
                    </div>
                    <button
                        type="submit"

                        value="Send"
                        onClick={SubmitData}
                    >
                        Book Your Demo
                    </button>

                </form>
            </div >
            {/* Slider Ends */}
        </div >
    )
}

export default Slider
