import React, { useEffect } from 'react'
import Navbar from '../Utilities/Navbar'
import Footer from '../Utilities/Footer'
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../Db/firebase';
import { Link } from 'react-router-dom'
import Whatsapp from '../Utilities/Whatsapp';

const Contact = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Course: "",
        Mobile: "",
        Message: ""

    });
    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Course, Mobile, Message } = userData;
        if (Name && Email && Course && Mobile && Message) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                alert('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    alert("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    alert("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                const docRef = await addDoc(collection(db, "Get_In_Touch_Form"), {
                    Name: Name,
                    Email: Email,
                    Course: Course,
                    Mobile: Mobile,
                    Message: Message
                });


                emailjs.sendForm('service_9jjce1y', 'template_pzhmwzp', form.current, 'fS_Fn-Qu2umrSHbxV')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                alert('Message Sent Successfully');

                setUserData({
                    Name: "",
                    Email: "",
                    Course: "",
                    Mobile: "",
                    Message: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                alert("Error adding document")
            }
        }
        else {
            alert('Please fill the data');
        }
    };

    return (
        <div>
            <Navbar />
            <Whatsapp/>

            <div className="contact-page">
                <div className="banner">
                    <img src='https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510110/Bliss%20School%20Of%20Music/Contact/banner_yqgdzy.png' alt="banner" />
                    <div className="banner-caption">
                        <h3><Link to={"/"}>Home</Link> / Contact Us</h3>
                    </div>
                </div>
                <section id="contact" class="contact mb-5">
                    <div class="container" data-aos="fade-up">

                        {/* <div className="heading">
                            <h3>Contact Us</h3>
                        </div> */}

                        <div class="row gy-4 ">

                            <div class="col-md-4">
                                <div class="info-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                                    <h3>Address</h3>
                                    <address>Tower-L, Dlf Capital Greens, Moti Nagar Karampura Industrial Area, Karam Pura, New Delhi, 110015</address>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="info-item info-item-borders phone">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg><h3>Phone Number</h3>
                                    <p><a href="tel: +919818670338">+91-98186-70338</a></p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="info-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M215.4 96H144 107.8 96v8.8V144v40.4 89L.2 202.5c1.6-18.1 10.9-34.9 25.7-45.8L48 140.3V96c0-26.5 21.5-48 48-48h76.6l49.9-36.9C232.2 3.9 243.9 0 256 0s23.8 3.9 33.5 11L339.4 48H416c26.5 0 48 21.5 48 48v44.3l22.1 16.4c14.8 10.9 24.1 27.7 25.7 45.8L416 273.4v-89V144 104.8 96H404.2 368 296.6 215.4zM0 448V242.1L217.6 403.3c11.1 8.2 24.6 12.7 38.4 12.7s27.3-4.4 38.4-12.7L512 242.1V448v0c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64v0zM176 160H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16z" /></svg><h3>Email</h3>
                                    <p><a href="mailto:blissschoolofmusic1@gmail.com">blissschoolofmusic1@gmail.com</a></p>
                                </div>
                            </div>

                        </div>
                        <div className="row" >
                            <div className="col-md-6 map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.773949875837!2d77.14851417434667!3d28.666486382540043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03f35c62c987%3A0x52e3a22b4c74347b!2sBliss%20School%20of%20Music!5e0!3m2!1sen!2sin!4v1696323168809!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                            </div>
                            <div className="col-md-6" >
                                <div class="form mt-5" id='contact'>
                                    <form method="post" ref={form} role="form" class="php-email-form">
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name="Name"
                                                    id="name"
                                                    placeholder='Your Name'
                                                    value={userData.Name}
                                                    onChange={postUserData}
                                                    required />
                                            </div>
                                            <div class="form-group col-md-6">
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name="Mobile"
                                                    id="Mobile"
                                                    placeholder='Mobile Number'
                                                    value={userData.Mobile}
                                                    onChange={postUserData}
                                                    required />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <input
                                                    className='form-control'
                                                    type="email"
                                                    name="Email"
                                                    id="email"
                                                    placeholder='Email'
                                                    value={userData.Email}
                                                    onChange={postUserData}
                                                    required />
                                            </div>
                                            <div class="form-group col-md-6">
                                                <select aria-label="Default select example"

                                                    value={userData.Course}
                                                    onChange={postUserData}
                                                    className="form-select"
                                                    name='Course'>
                                                    <option value="default" defaultValue='Select Course'>Select Course</option>
                                                    <option value="Piano">Piano</option>
                                                    <option value="Classical Guitar">Classical Guitar</option>
                                                    <option value="Acoustic Guitar">Acoustic Guitar</option>
                                                    <option value="Drums">Drums</option>
                                                    <option value="Keyboard">Keyboard</option>
                                                    <option value="Vocal">Vocal</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <textarea
                                                    className='form-control'
                                                    id="message"
                                                    cols="30"
                                                    rows="7"
                                                    placeholder='Your Message'
                                                    name="Message"
                                                    value={userData.Message}
                                                    onChange={postUserData}
                                                    required></textarea>
                                            </div>
                                        </div>

                                        <div class="text-center"><button type="submit" value="Send"
                                            onClick={SubmitData}>Send Message</button></div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <div className="branches">
                    <div className="heading">
                        <h3>Branches</h3>
                    </div>
                    <div className="branches-body">
                        <div className="branches-card">
                            <h6>Connaught place Inside Railway Officer club</h6>
                        </div>
                        <div className="branches-card">
                            <h6>Commonwealth games village Patparganj</h6>
                        </div>
                        <div className="branches-card">
                            <h6>Sangli Apartments Copernicus Marg</h6>
                        </div>
                        <div className="branches-card">
                            <h6>Ministry of External affair Residential complex Chanakyapuri</h6>
                        </div>
                        <div className="branches-card">
                            <h6>Defence Officer enclave SP marg Chanakyapuri </h6>
                        </div>
                        <div className="branches-card">
                            <h6>Tarapore officer enclave Rangpuri Vasant Kunj</h6>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact
