import React from 'react'
import Navbar from '../Utilities/Navbar'
import Footer from '../Utilities/Footer'
import { useEffect } from 'react'
import Whatsapp from '../Utilities/Whatsapp';

const TermsOfService = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div>
            <Navbar />
            <Whatsapp />

            <div className="terms-of-service">
                <div className="heading" style={{ width: '100%' }}>
                    <h3>Fees and payment deadlines</h3>
                </div>
                <div className="terms-of-service-body">
                    <div className="terms-card">
                        <h4>Fees and due date for payment</h4>
                        <ol>
                            <li>Fees are non-refundable.</li>
                            <li>Quarterly fees must be paid in full in advance.</li>
                            <li>The next quarterly fee is credited on the 30th day of the last month of each quarter. If you pay monthly, your payment will be made on the 30th of each month. Students who do not pay their fees by the stipulated date will be liable to pay a resumption fee of Rs 300 and quarterly/monthly fees if they wish to continue their classes subject to availability of seats. </li>
                            <li>AThe confirmation slip will be sent to the student after the fee has been paid or the amount has been deposited in the school's bank account.</li>
                        </ol>
                    </div>

                    <div className="terms-card">
                        <h4>Class times, attendance and Make-up rules</h4>
                        <ol>
                            <li>Instruments & Vocal Classes are held on days and times that are convenient for instructors and students. Times set in this way will not change during the month.</li>
                            <li>Students must attend classes regularly, be punctual, and spend time practicing and preparing. </li>
                            <li>Attendance will be recorded in the attendance register to avoid confusion and misunderstandings between students and instructors.</li>
                            <li>Each student's make-up class is considered only one class per month and must complete the class within 10 days from the date of the original class. If you are absent without permission, you will not be eligible for make-up classes. If a student misses a rescheduled lesson, the lesson will not be rescheduled. However, lessons missed by the instructor will be made up within two weeks or during the month.</li>
                            <li>Discipline should always be maintained. Please keep your cell phone silent during class.</li>
                        </ol>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default TermsOfService
