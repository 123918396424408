import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"


const firebaseConfig = {
    apiKey: "AIzaSyB1dHxVTg3F5MPkSk0QAbZkRI0tHnCx2v8",
    authDomain: "bliss-school-of-music.firebaseapp.com",
    projectId: "bliss-school-of-music",
    storageBucket: "bliss-school-of-music.appspot.com",
    messagingSenderId: "956930406008",
    appId: "1:956930406008:web:39352c7682806d09b65f8d",
    measurementId: "G-3EMLHT1L2Y"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);