import React, { useState, useEffect } from 'react'
import Navbar from '../Utilities/Navbar'
import Footer from '../Utilities/Footer'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player/youtube'
import Whatsapp from '../Utilities/Whatsapp';


const Gallery = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [isShowninfrastructure, setIsShowninfrastructure] = useState(false);
    const [isShownstudents, setIsShownstudents] = useState(false);
    const [isShownachievements, setIsShownachievements] = useState(false);
    const [isShownall, setIsShownall] = useState(true);
    const [isShownVideos, setIsShowVideos] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const infrastructure = event => {
        setIsShowninfrastructure(current => !current);
        setIsShownstudents(false);
        setIsShownachievements(false);
        setIsShownall(false);
    };
    const students = event => {
        setIsShownstudents(current => !current);
        setIsShowninfrastructure(false);
        setIsShownachievements(false);
        setIsShownall(false);
        setIsShowVideos(false);
    };
    const achievements = event => {
        setIsShownachievements(current => !current);
        setIsShowninfrastructure(false);
        setIsShownstudents(false);
        setIsShownall(false);
        setIsShowVideos(false);
    };
    const all = event => {
        setIsShownall(current => !current);
        setIsShowninfrastructure(false);
        setIsShownstudents(false);
        setIsShownachievements(false);
        setIsShowVideos(false);
    };
    const videos = event => {
        setIsShowVideos(current => !current);
        setIsShownall(false);
        setIsShowninfrastructure(false);
        setIsShownstudents(false);
        setIsShownachievements(false);
    };

    return (
        <div>
            <Navbar />
            <Whatsapp />

            <div className="gallery">
                <div className="banner">
                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696511611/Bliss%20School%20Of%20Music/Gallery/banner_rsk2zb.png" alt="banner" />
                    <div className="banner-caption">
                        <h3><Link to={"/"}>Home</Link> / Gallery</h3>
                    </div>
                </div>

                <div id='pages-gallery'>

                    <div className="pages-gallery">
                        <div className="heading" style={{ width: '100%' }}>
                            <h3 style={{ color: '#000' }}>Gallery</h3>
                        </div>
                        <div className="gallery-filter">
                            <button id='all' onClick={all}>All </button>
                            <button
                                id='infrastructure'
                                onClick={infrastructure}>
                                infrastructure
                            </button>
                            <button id='achievements' onClick={achievements}>achievements </button>
                            <button id='students' onClick={students}>students </button>
                            <button id='students' onClick={videos}>Videos </button>
                        </div >

                        {isShownall && (
                            <div class="row">
                                <div class="column">
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805055/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-22_at_12.26.11_AM_fprixv.jpg" alt="" className='infrastructure' />
                                   <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696589059/Bliss%20School%20Of%20Music/Gallery/red_wood__r2dyhh.png" alt="" />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805052/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.31_2_kvqs1d.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.31_1_gxmoqe.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.25_1_xwvadz.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805048/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.07.30_jdq2af.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.07.31_w5zvy5.jpg" alt=""
                                        className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.25_esllle.jpg" alt=""
                                        className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805052/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.30_cq3imw.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805053/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.40_1_tdkyf7.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805054/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.41_m2hizf.jpg" alt="" className='achievements' />
                                </div>
                                <div class="column">
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805054/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-22_at_12.22.13_AM_etuj9r.jpg" alt="" className='infrastructure' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.28_feogik.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.29_eh6vsi.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.29_1_ql5jjv.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.30_1_vbav3b.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510110/Bliss%20School%20Of%20Music/Achievements/1_dssgpw.png" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510109/Bliss%20School%20Of%20Music/Achievements/2_hx8m5v.png" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805053/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.39_sieo25.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805050/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.27_1_hbgtp7.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805050/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.27_2_qmslyg.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805053/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.32_d98vsr.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805052/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.31_ts6y0l.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805050/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.26_hqzs2d.jpg" alt="" className='students' />
                                </div>
                                <div className='column'>
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696568278/Bliss%20School%20Of%20Music/Gallery/1_nfdr9e.jpg" alt="" />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805050/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.27_pg6inz.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.24_1_idclwb.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805053/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.40_ddkr4q.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805056/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-22_at_12.33.52_AM_jhzcll.jpg" alt="" className='infrastructure' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.07.31_1_lo4b2d.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.24_2_orf4ug.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.24_iifksi.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.26_1_t7dt4q.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805050/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.26_2_qjskof.jpg" alt="" className='students' />
                                </div>

                            </div>
                        )}

                        {isShowninfrastructure && (
                            <div class="row">
                                <div class="column">
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805055/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-22_at_12.26.11_AM_fprixv.jpg" alt="" className='infrastructure' />
                                </div>
                                <div class="column">
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805054/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-22_at_12.22.13_AM_etuj9r.jpg" alt="" className='infrastructure' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696568278/Bliss%20School%20Of%20Music/Gallery/1_nfdr9e.jpg" alt="" />
                                </div>
                                <div className='column'>
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805056/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-22_at_12.33.52_AM_jhzcll.jpg" alt="" className='infrastructure' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696589059/Bliss%20School%20Of%20Music/Gallery/red_wood__r2dyhh.png" alt="" />
                                </div>
                            </div>

                        )}

                        {isShownstudents && (
                            <div class="row">
                                <div class="column">
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.25_1_xwvadz.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805048/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.07.30_jdq2af.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.07.31_w5zvy5.jpg" alt=""
                                        className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.25_esllle.jpg" alt=""
                                        className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.26_1_t7dt4q.jpg" alt="" className='students' />
                                </div>
                                <div class="column">
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.07.31_1_lo4b2d.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.24_2_orf4ug.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805049/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.24_iifksi.jpg" alt="" className='students' />

                                </div>
                                <div className='column'>
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805050/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.26_2_qjskof.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805050/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.26_hqzs2d.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805053/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.32_d98vsr.jpg" alt="" className='students' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805052/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.31_ts6y0l.jpg" alt="" className='students' />
                                </div>
                            </div>
                        )}

                        {isShownachievements && (
                            <div class="row">
                                <div class="column">
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805050/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.27_1_hbgtp7.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805050/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.27_2_qmslyg.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805050/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.27_pg6inz.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.24_1_idclwb.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805053/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.40_ddkr4q.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805053/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.39_sieo25.jpg" alt="" className='achievements' />
                                </div>
                                <div class="column">
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.28_feogik.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.29_eh6vsi.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.29_1_ql5jjv.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.30_1_vbav3b.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510110/Bliss%20School%20Of%20Music/Achievements/1_dssgpw.png" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510109/Bliss%20School%20Of%20Music/Achievements/3_avt48m.png" alt="" className='achievements' />

                                </div>
                                <div class="column">


                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805052/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.31_2_kvqs1d.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805051/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.31_1_gxmoqe.jpg" alt="" className='achievements' />

                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805052/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.30_cq3imw.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805053/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.40_1_tdkyf7.jpg" alt="" className='achievements' />
                                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695805054/Bliss%20School%20Of%20Music/Gallery/WhatsApp_Image_2023-09-08_at_21.08.41_m2hizf.jpg" alt="" className='achievements' />
                                </div>

                            </div>

                        )}

                        {isShownVideos && (
                            <div className="video-body gallery-video">
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/hhMh3rHZmrw?si=A2t0oS3L9hRAqX_V'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/uRnnAHssKI0?si=k-Rlc8_8YsCF3F5B'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/kSBS4uo72Eg?si=ZtiHi_09_-W9Q7TH'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/pIaHPpx_1PU?si=Kjbui3kIZK53gLwC'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/aNKS-mFBDpg?si=byTE88jLlqCDXooZ'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/o0IJj4_6IRQ?si=2w4MH0jc3IIw_x_B'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/X5rSRb3iczc?si=fj-zaPWihVAZcoFT'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/mTtHPIxB4tw?si=ZeYOsP6mmQXHGyZh'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/fKgOd8kNEGY?si=MaWsBh491VDd-qun'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/WPwBThfPPAg?si=2kRrqRc2j6a8Ws4M'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/rr4obxllFfs?si=sOS4T-pe8ylJ61XH'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>

                                <div className="video-body-card">
                                    <ReactPlayer url='https://youtu.be/U4pjn18U9Nw?si=gvTiT00u1c74Kj-K'
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        autoplay={false}
                                        light={true}
                                        playing={false}
                                    />
                                </div>
                            </div>
                        )}

                    </div>
                </div >
            </div>
            <Footer />
        </div>
    )
}

export default Gallery
