import React, { useEffect } from 'react'
import Navbar from '../Utilities/Navbar'
import Footer from '../Utilities/Footer'
import { Link } from 'react-router-dom'
import Whatsapp from '../Utilities/Whatsapp';

const About = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Navbar />
            <Whatsapp />

            <div className="about-page">
                <div className="banner">
                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510108/Bliss%20School%20Of%20Music/About/banner_dw9th4.png" alt="banner" />
                    <div className="banner-caption">
                        <h3><Link to={"/"}>Home</Link> / About Us</h3>
                    </div>
                </div>

                <div className="about-body">
                    <div className="founder-message">
                        <div className="img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696569392/Bliss%20School%20Of%20Music/About/founder_k39s1e.jpg" alt="" />
                            <div className="img-before"></div>
                        </div>

                        <div className="founder-message-caption">
                            <h3>Founder's Message</h3>
                            <p>
                                Music has the power to open your heart and raise your consciousness. Music makes you more aware and express emotions that words sometime cannot. Including music, all others forms of classical arts are near to the heart which help by start giving you the glimpse of higher purpose of life. Music is not a hobby or mere entertainment. In actual, when you engage with music, it leads to the realization of inner-self.</p>
                            <p>
                                Bliss School of Music was founded in 2012 with one simple goal in mind: To create a space where people of all ages and cultures could learn and grow their musical gifts. It’s a universal language that connects us all. I firmly believe in the magic of music, and I wanted Bliss School to be a place where that magic could be shared. My vision for Bliss School of Music was to create an environment where learning and creating music was at the heart of everything we do. With the help of expert guidance and a supportive community, we’ve grown into a centre of musical excellence. We’ve had the privilege of supporting countless students on their musical journeys and I’m so proud to see them grow and thrive. Our school isn’t just about teaching music, it’s about inspiring a lifelong passion for music.
                            </p>
                        </div>
                    </div>
                    <div className="why-choose-us">
                        <div className="heading">
                            <h3>Why Choose Bliss School Of Music</h3>
                        </div>
                        <p>We believe that music is more than just notes and scales, it’s an art form that requires creativity, discipline, and commitment. Not only do our students learn to play an instrument or sing, but they also learn the ins and outs of music theory. At Bliss, one of the most important things we strive for is excellence. We don’t just teach you how to play music, we teach you how to be great at it. With our rigorous training, our regular concerts to show and refine our students’ skills with the world-class institutions like Rockschool, Trinity College London, and ABRSM, we help our students reach their full potential.</p>
                        <p>We believe in going above and beyond. Our holistic approach to music education seeks to develop not only musicians but also passionate music lovers. Music is an art form that transcends borders, and our music education curriculum reflects this. Here are some of the key elements of a comprehensive musical education:
                        </p>
                        <p>Regular Concerts: The stage is where true growth takes place. Our students get the chance to perform in front of a live audience regularly, perfecting their skills and building their confidence. These concerts aren’t just events, they’re milestones in our students’ musical journey.
                        </p>
                        <p>Music Theory: Having a good understanding of music theory is key to any musician. We believe in the power of language in music, and our dedicated music theory lessons give our students the knowledge and skills they need to be successful on their instruments.
                        </p>
                        <p>International Music Exams: Our students are encouraged to take International Music Exams, which are offered by renowned institutions like Rockschool and Trinity College London, as well as ABRSM. The International Music Exams not only help our students develop their musical skills but also contribute to their overall musical development.
                        </p>
                        <p>What we do at Bliss School of Music isn’t just about creating musicians, it’s about creating a community of people who love music and believe in its power to uplift, heal, and unite. Come join us on this journey of music adventure.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About
