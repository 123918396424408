import React, { useEffect } from 'react'
import Navbar from '../Utilities/Navbar'
import Footer from '../Utilities/Footer'
import { Link } from 'react-router-dom'
import Whatsapp from '../Utilities/Whatsapp';

const OurTeam = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Navbar />
            <Whatsapp />

            <div className="team-page">
                <div className="banner">
                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510114/Bliss%20School%20Of%20Music/Team/banner_wmmw8t.png" alt="banner" />
                    <div className="banner-caption">
                        <h3><Link to={"/"}>Home</Link> / Our Team</h3>
                    </div>
                </div>

                <div className="team-body">
                    <div className="heading" style={{ width: '100%' }}>
                        <h3>Our Team</h3>
                    </div>

                    <div className="team-card">
                        <div className="team-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697281246/Bliss%20School%20Of%20Music/Team/Untitled_design_cbwiil.png" alt="" />
                        </div>
                        <div className="team-text">
                            <div className="team-title">
                                <h3>Pramod Kumar</h3>
                                <span>Piano & Guitar Teacher</span>
                            </div>
                            <div className="team-caption">
                                <p>After working as a western music teacher for some years in leading music schools in Delhi, I set up the first small music centre in the year 2012 at our residence at K. G. Marg and my happiness partner gave it a beautiful name as Bliss School of Music. It has been now big 10 years of learning at Delhi School of Music. My learnings at DSM has contributed a lot in my music journey and helped to set up music learning platforms for the learners of every age group.
                                </p>
                                <p><b>Qualifications:</b>
                                    <ul>
                                        <li>
                                            - Masters in Hotel management
                                        </li>
                                    </ul>
                                </p>

                                <p><b>Certifications:</b>
                                    <ul>
                                        <li>
                                            - Grade six in both classical and plectrum Guitar
                                        </li>
                                        <li>
                                            - Grade Four in Piano from Trinity
                                        </li>
                                        <li>
                                            - Grade six in theory from ABRSM as well as trinity
                                        </li>

                                    </ul>

                                </p>

                            </div>

                        </div>
                    </div>

                    <div className="team-card">
                        <div className="team-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696583322/Bliss%20School%20Of%20Music/Team/Paiva_Ramai_vzfjj5.png" alt="" />
                        </div>
                        <div className="team-text">
                            <div className="team-title">
                                <h3>Paiva Ramai</h3>
                                <span>Western Vocal & Piano Teacher </span>
                            </div>
                            <div className="team-caption">
                                <p>Paiva Ramai is a singer and a music educator.
                                    She has a degree in both Western Classical and Rock & Pop vocal from Trinity College London Board.She was also a Soprano at Delhi Chamber Choir.</p>
                                <p> She has performed in various places of national and international prominence such as
                                    <ul>
                                        <li>-Urban Climate Film Festival</li>
                                        <li>-Autumn festival:A prelude to hornbill festival 2022</li>
                                        <li>-North East India Choral Competition</li>
                                    </ul>
                                </p>
                                <p><b>Qualifications:</b>
                                    <ul>
                                        <li>
                                            - Bachelors of Arts from Maitreyi college,Delhi University
                                        </li>
                                    </ul>

                                </p>

                                <p><b>Certifications:</b>
                                    <ul>
                                        <li>
                                            - Grade 8 Trinity Rock & Pop Vocals

                                        </li>
                                        <li>
                                            - Grade 8 Trinity Classical
                                        </li>
                                    </ul>

                                </p>

                            </div>

                        </div>
                    </div>

                    <div className="team-card">
                        <div className="team-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697281244/Bliss%20School%20Of%20Music/Team/Untitled_design_1_lmbm1l.png" alt="" />
                        </div>
                        <div className="team-text">
                            <div className="team-title">
                                <h3>Deepanshu Nanda</h3>
                                <span>Guitar & Piano Teacher </span>
                            </div>
                            <div className="team-caption">
                                <p>I am a Music Teacher , I have been playing Guitar and Piano for many years. I have been in multiple ensembles and bands. I am a creative professional with extensive project experience from concept to development. Talents include reading sheet music, knowledge in music theory, ear-training, knowledge of the fret-board as well as technical ability and musical creativity </p>

                                <p><b>Qualifications:</b>
                                    <ul>
                                        <li>-  Bachelor in Commerce from Delhi University</li>
                                        <li>- Grades in Music from Delhi School of Music</li>
                                    </ul>

                                </p>

                                <p><b>Certifications:</b>
                                    <ul>
                                        <li>
                                            - Acoustic Guitar Grade 6
                                        </li>
                                        <li>
                                            - Classical Guitar Grade 5
                                        </li>

                                        <li>
                                            - Piano Grade 5
                                        </li>

                                        <li>
                                            - Music theory
                                        </li>
                                    </ul>

                                </p>

                            </div>

                        </div>
                    </div>

                    <div className="team-card">
                        <div className="team-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510114/Bliss%20School%20Of%20Music/Team/Rachit_Juyal_fwfp3b.png" alt="" />
                        </div>
                        <div className="team-text">
                            <div className="team-title">
                                <h3>Rachit Juyal </h3>
                                <span>Guitar & Music Theory teacher</span>
                            </div>
                            <div className="team-caption">
                                <p> I write songs, I have been teaching guitar for 5 years and I perform</p>

                                <p><b>Qualifications:</b>
                                    <ul>
                                        <li>
                                            - B.Tech(Mechanical engineering)
                                        </li>
                                    </ul>

                                </p>

                                <p><b>Certifications:</b>
                                    <ul>
                                        <li>
                                            - 8th grade certificate in plectrum guitar from Trinity College of London

                                        </li>
                                    </ul>

                                </p>

                            </div>

                        </div>
                    </div>

                    <div className="team-card">
                        <div className="team-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510115/Bliss%20School%20Of%20Music/Team/Zhavei_O_Vemai_cazwqp.png" alt="" />
                        </div>
                        <div className="team-text">
                            <div className="team-title">
                                <h3>Zhavei O Vemai</h3>
                                <span>Guitar & Piano Teacher</span>
                            </div>
                            <div className="team-caption">
                                <p>I'm from Manipur , and did my graduation from Manipur college . As a  music enthusiasts , joined the Delhi school of music in 2018 , and a music  teacher at the same time with Bliss Music. I have been in teaching for past 5 years and still counting .
                                </p>
                                <p><b>Qualifications:</b>
                                    <ul>
                                        <li>
                                            - B.A English Hons
                                        </li>
                                    </ul>

                                </p>

                                <p><b>Certifications:</b>
                                    <ul>
                                        <li>
                                            - Grade 6 Western Classical (Trinity)
                                        </li>
                                        <li>
                                            - Grade 8 Plectrum (Trinity)
                                        </li>
                                    </ul>

                                </p>

                            </div>

                        </div>
                    </div>

                    <div className="team-card">
                        <div className="team-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510114/Bliss%20School%20Of%20Music/Team/Nikhil_Dhingra_ulgzt9.png" alt="" />
                        </div>
                        <div className="team-text">
                            <div className="team-title">
                                <h3>Nikhil Dhingra</h3>
                                <span>drums teacher</span>
                            </div>
                            <div className="team-caption">
                                <p>Born and bought up in Rohtak, Haryana.
                                    Did schooling in 2014 from DAV, Rohtak
                                    And Btech in 2018 from MDU Rohtak
                                    Worked as software engineer in Genpact for a year and half.
                                    In 2020, i started teaching musical instruments under the guidance of Pramod Kumar.
                                    Now doing this full time. Also taught 100+ students in my hometown Rohtak.</p>

                                <p><b>Qualifications:</b>
                                    <ul>
                                        <li>
                                            - B.TECH (Computers Science)
                                        </li>
                                    </ul>

                                </p>

                                <p><b>Certifications:</b>
                                    <ul>
                                        <li>
                                            - Grade 8 Drumkit (Trinity College of London)

                                        </li>
                                    </ul>

                                </p>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OurTeam
