import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

  const up = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }

  return (
    <div>
      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">

              <div class="col-lg-4 col-md-6">
                <div class="footer-info">
                  <h3>Bliss School Of Music</h3>
                  <p>
                    Tower-L, Dlf Capital Greens, Moti Nagar Karampura Industrial Area, Karam Pura, New Delhi, 110015.<br /><br />
                    <strong>Phone:</strong><a href="tel: +919818670338"> +91-98186-70338</a><br />
                    <strong>Email:</strong><a href="mailto:blissschoolofmusic1@gmail.com"> blissschoolofmusic1@gmail.com</a><br />
                  </p>
                  <div class="social-links mt-3">
                    <a href="https://wa.me/919818670338" class="twitter">
                      <svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m256 22.24c-129.102 0-233.76 104.658-233.76 233.76 0 42.598 11.399 82.531 31.306 116.924l-31.306 116.836 116.836-31.306c34.394 19.906 74.327 31.306 116.924 31.306 129.102 0 233.76-104.657 233.76-233.76 0-129.102-104.658-233.76-233.76-233.76zm137.081 345.999-20.171 20.172c-40.573 25.418-119.046-1.935-183.216-66.104-64.169-64.17-91.522-142.643-66.104-183.216l20.172-20.172c6.331-6.331 16.69-6.331 23.02 0l49.397 49.397c6.331 6.331 6.331 16.69 0 23.021l-20.172 20.172c-9.026 16.561 3.11 48.332 29.632 74.854 26.522 26.521 58.293 38.657 74.854 29.632l20.172-20.172c6.331-6.331 16.69-6.331 23.02 0l49.397 49.397c6.33 6.329 6.33 16.688-.001 23.019z" fill-rule="evenodd" /></svg>
                    </a>
                    <a href="https://instagram.com/bliss_school_of_music?igshid=MWZjMTM2ODFkZg==" class="instagram"><svg viewBox="0 0 512.00096 512.00096" xmlns="http://www.w3.org/2000/svg"><path d="m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875 138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm-117.40625 395.996094c-77.195312 0-139.996094-62.800782-139.996094-139.996094s62.800782-139.996094 139.996094-139.996094 139.996094 62.800782 139.996094 139.996094-62.800782 139.996094-139.996094 139.996094zm143.34375-246.976563c-22.8125 0-41.367188-18.554687-41.367188-41.367187s18.554688-41.371094 41.367188-41.371094 41.371094 18.558594 41.371094 41.371094-18.558594 41.367187-41.371094 41.367187zm0 0" /><path d="m256 146.019531c-60.640625 0-109.980469 49.335938-109.980469 109.980469 0 60.640625 49.339844 109.980469 109.980469 109.980469 60.644531 0 109.980469-49.339844 109.980469-109.980469 0-60.644531-49.335938-109.980469-109.980469-109.980469zm0 0" /><path d="m399.34375 96.300781c-6.257812 0-11.351562 5.09375-11.351562 11.351563 0 6.257812 5.09375 11.351562 11.351562 11.351562 6.261719 0 11.355469-5.089844 11.355469-11.351562 0-6.261719-5.09375-11.351563-11.355469-11.351563zm0 0" /></svg></a>
                    <a href="https://youtube.com/@PramodKumarkartik?si=lmIrbJJuQBpapeFm " class="linkedin"><svg id="Layer_1" enable-background="new 0 0 152 152" height="512" viewBox="0 0 152 152" width="512" xmlns="http://www.w3.org/2000/svg"><path id="Icon" d="m116.5 29.5h-81c-13.6 0-24.5 11.1-24.5 24.6v43.8c0 13.7 11.1 24.6 24.6 24.6h80.9c13.7 0 24.6-11.1 24.6-24.6v-43.8c0-13.5-11.1-24.6-24.6-24.6zm-57.6 66.5v-40l34.2 20z" /></svg></a>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li> <Link onClick={up} to={"/"}>Home</Link></li>
                  <li> <Link onClick={up} to={"/about"}>About us</Link></li>
                  <li> <Link onClick={up} to={"/our-team"}>Our Team</Link></li>
                  <li> <Link onClick={up} to={"/contact"}>Contact Us</Link></li>
                  <li> <Link onClick={up} to={"/terms-of-service"}>Rules & Regulations</Link></li>
                  <li> <Link onClick={up} to={"/privacy-policy"}>Privacy policy</Link></li>
                </ul>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Our Courses</h4>
                <ul>
                  <li> <Link onClick={up} to={"/courses"}>Piano</Link></li>
                  <li> <Link onClick={up} to={"/courses"}>Classical Guitar</Link></li>
                  <li> <Link onClick={up} to={"/courses"}>Acoustic Guitar</Link></li>
                  <li> <Link onClick={up} to={"/courses"}>Drums</Link></li>
                  <li> <Link onClick={up} to={"/courses"}>Keyboard</Link></li>
                  <li> <Link onClick={up} to={"/courses"}>Vocal</Link></li>

                </ul>
              </div>

              <div class="col-lg-4 col-md-6 footer-newsletter">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.773949875837!2d77.14851417434667!3d28.666486382540043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03f35c62c987%3A0x52e3a22b4c74347b!2sBliss%20School%20of%20Music!5e0!3m2!1sen!2sin!4v1696323168809!5m2!1sen!2sin" width="100%" height="50%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <h4>Our Newsletter</h4>
                <p>Stay in the loop! Subscribe to our Newsletter and never miss an update</p>
                <form action="" method="post">
                  <input type="email" name="email" /><input type="submit" value="Subscribe" />
                </form>

              </div>

            </div>
          </div>
        </div>

        <div class="container">
          <div class="copyright">
            &copy; Copyright {new Date().getFullYear()} by <strong><span><a href="https://www.ahsassuredservices.com">AHS Assured Services</a></span></strong>. All Rights Reserved
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
