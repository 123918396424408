import React, { useEffect } from 'react'
import Navbar from '../Utilities/Navbar'
import Footer from '../Utilities/Footer'
import Slider from '../Utilities/Slider'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import ReactPlayer from 'react-player/youtube'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Whatsapp from '../Utilities/Whatsapp';

const Home = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Whatsapp />
      <Navbar />
      <Slider />
      {/* <Uparrow /> */}

      {/* about */}
      <div className="about">
        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510108/Bliss%20School%20Of%20Music/About/about-bg_pst3da.jpg" alt="about" />
        <div className="about-text">
          <h3>About Bliss School Of Music</h3>
          <p>Bliss School of Music, founded by Mr. Pramod Kumar in 2012, stands as a distinguished institution in the heart of the Capital, dedicated to nurturing the musical talents of over 2000 students. Our mission extends beyond imparting Western classical music knowledge; it is a commitment to our students a lifelong passion for music while equipping them with the skills and experience necessary to achieve their musical dreams, whether it is a professional career or a cherished hobby.</p>
          <p>Our approach extends beyond typical music classes. We believe in a holistic musical education that encompasses regular concerts to showcase and refine our students' talents. We emphasize the importance of music theory lessons to provide a strong foundation for their musical journey. Moreover, we take pride in preparing our students to excel in International Music Exams offered by prestigious institutions such as Rockschool, Trinity College London, and ABRSM. These examinations not only validate their musical proficiency but also contribute significantly to their comprehensive musical development.</p>
        </div>
      </div>
      {/* about ends */}

      {/* courses */}
      <div className="courses">
        <div className="heading">
          <h3>Our Courses</h3>
        </div>
        <div className="courses-body">
          <div className="courses-card">
            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510112/Bliss%20School%20Of%20Music/Courses/piano_bzkwbb.png" alt="piano" />
            <h4>Piano</h4>
          </div>


          <div className="courses-card">
            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696511187/Bliss%20School%20Of%20Music/Courses/classical_guitar_dq0utv.png" alt="piano" />
            <h4>Classical Guitar</h4>
          </div>

          <div className="courses-card">
            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510112/Bliss%20School%20Of%20Music/Courses/acoustic_guitar_lsnldz.png" alt="piano" />
            <h4>Acoustic Guitar</h4>
          </div>

          <div className="courses-card">
            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510112/Bliss%20School%20Of%20Music/Courses/drums_envtva.png" alt="piano" />
            <h4>Drums</h4>
          </div>

          <div className="courses-card">
            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510112/Bliss%20School%20Of%20Music/Courses/keyboard_mncvli.png" alt="piano" />
            <h4>Keyboard</h4>
          </div>


          <div className="courses-card">
            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510113/Bliss%20School%20Of%20Music/Courses/vocal_rqlr4f.png" alt="piano" />
            <h4>Vocal</h4>
          </div>

        </div>
      </div>
      {/* courses ends */}

      {/* affiliations */}
      <section id='affiliations'>

        <div className='affiliations'>
          <div className="heading">
            <h3>Affiliation</h3>
          </div>

          <div className='cer-body'>
            <div className="cer-body-text">

            </div>
            <div className='cer-body-img'>

              <ul id="Frames">
                <li className="Frame"><img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510110/Bliss%20School%20Of%20Music/Affiliations/abrsm_nog86u.jpg" className='img-fluid' alt="msme" /></li>
              </ul>

              <ul id="Frames">
                <li className="Frame"><img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510110/Bliss%20School%20Of%20Music/Affiliations/rsl_n55fsg.webp" className='img-fluid' alt="startup" /></li>
              </ul>

              <ul id="Frames">
                <li className="Frame"><img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510110/Bliss%20School%20Of%20Music/Affiliations/trinity_yj4nh7.webp" className='img-fluid' alt="startup" /></li>
              </ul>

            </div>
          </div>
        </div>
      </section>
      {/* affiliations ends */}

      {/* team */}
      <div className="team">
        <section id="team">
          <div className="heading">
            <h3>Our Team</h3>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <AnimationOnScroll animateIn="animate__slideInRight">
                  <div className="team-member">
                    <figure>
                      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697281246/Bliss%20School%20Of%20Music/Team/Untitled_design_cbwiil.png" alt="" className='img-responsive' />
                    </figure>
                    <h4>Pramod Kumar</h4>
                    <p>Piano & Guitar Teacher</p>
                  </div>
                </AnimationOnScroll>
              </div>

              <div className="col-md-2">
                <AnimationOnScroll animateIn="animate__slideInRight">
                  <div className="team-member">
                    <figure>
                      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696583322/Bliss%20School%20Of%20Music/Team/Paiva_Ramai_vzfjj5.png" alt="" className="img-responsive" />

                    </figure>
                    <h4>Paiva Ramai</h4>
                    <p>Western Vocal & Piano Teacher</p>
                  </div>
                </AnimationOnScroll>

              </div>

              <div className="col-md-2">
                <AnimationOnScroll animateIn="animate__slideInRight">
                  <div className="team-member">
                    <figure>
                      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697281244/Bliss%20School%20Of%20Music/Team/Untitled_design_1_lmbm1l.png" alt="" className="img-responsive" />

                    </figure>
                    <h4>Deepanshu Nanda</h4>
                    <p>Guitar & Piano Teacher</p>
                  </div>
                </AnimationOnScroll>

              </div>

              <div className="col-md-2">
                <AnimationOnScroll animateIn="animate__slideInRight">
                  <div className="team-member">
                    <figure>
                      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510114/Bliss%20School%20Of%20Music/Team/Rachit_Juyal_fwfp3b.png" alt="" className="img-responsive" />

                    </figure>
                    <h4>Rachit Juyal</h4>
                    <p>Guitar & Music Theory teacher</p>
                  </div>
                </AnimationOnScroll>

              </div>

              <div className="col-md-2">
                <AnimationOnScroll animateIn="animate__slideInRight">
                  <div className="team-member">
                    <figure>
                      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510115/Bliss%20School%20Of%20Music/Team/Zhavei_O_Vemai_cazwqp.png" alt="" className="img-responsive" />

                    </figure>
                    <h4>Zhavei O Vemai</h4>
                    <p>Guitar & Piano Teacher</p>
                  </div>
                </AnimationOnScroll>

              </div>

              <div className="col-md-2">
                <AnimationOnScroll animateIn="animate__slideInRight">
                  <div className="team-member">
                    <figure>
                      <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510114/Bliss%20School%20Of%20Music/Team/Nikhil_Dhingra_ulgzt9.png" alt="" className="img-responsive" />

                    </figure>
                    <h4>Nikhil Dhingra</h4>
                    <p>drums teacher</p>
                  </div>
                </AnimationOnScroll>

              </div>

            </div>
          </div>
        </section>
      </div>
      {/* team ends */}

      {/* achievements */}
      <section id='achievements'>
        <div className="heading">
          <h3>Achievements</h3>
        </div>
        <div className='achievements'>

          <div className="achievements-content">
            <div className='achievements-content-img'>
              <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510110/Bliss%20School%20Of%20Music/Achievements/1_dssgpw.png" alt="3" className='img-fluid' />
              <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510109/Bliss%20School%20Of%20Music/Achievements/3_avt48m.png" alt="1" className='img-fluid' />
              <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510109/Bliss%20School%20Of%20Music/Achievements/2_hx8m5v.png" alt="2" className='img-fluid' />
              <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510109/Bliss%20School%20Of%20Music/Achievements/4_geshpc.png" alt="2" className='img-fluid' />
            </div>

          </div>
        </div>
      </section>
      {/* achievements ends */}

      {/* testimonials */}
      <div class="testimonials">
        <div className="heading">
          <h3>Testimonials</h3>
        </div>
        <div className="carousel">
          <div class="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} interval={4000} swipeable	={false}>
              <div className='carousel-body'>

                <div className="testimonial-card">
                  <div class="testimonial-caption">
                    <h6>Priyam Wadhwa</h6>
                    <p>Bliss School of Music is of course a brilliant school, teaching the skills, moving their students up grades but it is so much more than that. It is a community of creative musicians and I am so pleased that my son is a part of it. Knowledge of all types of music genres blows me away and that is definitely down to being a part of this school.
                    </p>
                  </div>
                </div>

                <div className="testimonial-card">
                  <div class="testimonial-caption">
                    <h6>Saloni </h6>
                    <p> Drumming lessons from Nikhil is what I’m very much looking forward to attending which are never ever disappointing. Nikhil is an absolute star, a highly qualified drummer who’s learning, increasing and improving my drumming skills from the start. The Bliss School of Music is a brilliant place which I’d like to recommend to anyone who’s interested in learning or improving playing an instrument.</p>
                  </div>
                </div>

              </div>

              <div className='carousel-body'>

                <div className="testimonial-card">
                  <div class="testimonial-caption">
                    <h6>Robin Rai</h6>
                    <p>Bliss music is one of the best schools in New Delhi to learn music. All teachers are professional and very well known in the local music scene. They focus more on the ear training of the students which is actually the most important part of the music training. All sessions are conducted on a one-on-one basis in both the online mode (since most of the students are living abroad) and offline modes (at various campuses across the Delhi NCR.) Theory sessions are conducted as well, which gives you the ability to read and write music.</p>
                  </div>
                </div>

                <div className="testimonial-card">
                  <div class="testimonial-caption">
                    <h6>Sonal Agarwal</h6>
                    <p>I've had the privilege of being taught by both Mr. Pramod and Mr. Deepanshu at the Bliss School of Music, and I must say, their musical expertise is truly remarkable. What sets them apart is their exceptional patience and dedication in guiding me through the nuances of playing the keyboard, even when I struggle to find time for practice. They are outstanding educators, and I wholeheartedly recommend Bliss School of Music to learners of all ages who aspire to explore the world of music.</p>
                  </div>
                </div>

              </div>
              <div className='carousel-body'>

                <div className="testimonial-card">
                  <div class="testimonial-caption">
                    <h6>Ashok</h6>
                    <p>A great environment to learn under such able teachers. The personal touch from the teachers has made a great difference. Their care and attention has been the single most reason for the development of my son. My sincere gratitude and thanks to Mr Pramod for bringing a great music culture among kids. Keep going.</p>
                  </div>
                </div>

                <div className="testimonial-card">
                  <div class="testimonial-caption">
                    <h6>Shweta Srinivasan</h6>

                    <p>I've been learning guitar from Bliss Music Institute for the past two years. I have been consistent with my learning because of the talented teachers, who are not only amazing at their craft but also make the learning process fun and easy. My classes are a mix of learning songs (strumming ) and preparing for Trinity music grade exams. If you've been wanting to learn an instrument and don't know how or where to start, this institute is the place where you can start your journey.</p>
                  </div>
                </div>

              </div>
            </Carousel>
          </div>
        </div>
      </div>
      {/* testimonials */}

      {/* gallery starts */}
      <section id='gallery'>
        <div className="heading">
          <h3>Gallery</h3>
        </div>
        <div className='gallery'>
          <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696511611/Bliss%20School%20Of%20Music/Gallery/g1_ustn8g.png" alt="" />
          <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696511612/Bliss%20School%20Of%20Music/Gallery/g2_ftbbry.png" alt="" />
          <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696511610/Bliss%20School%20Of%20Music/Gallery/g3_mjxhsa.png" alt="" />
          <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696511610/Bliss%20School%20Of%20Music/Gallery/g4_xf8oeo.png" alt="" />
        </div >
      </section >
      {/* gallery ends */}

      {/* Videos */}
      <div id='video'>
        <div className="heading">
          <h3>Videos</h3>
        </div>
        <div className="carousel">
          <div class="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} swipeable	={false}	>
              <div className='carousel-body'>
                <div className="testimonial-card">
                  <ReactPlayer url='https://youtu.be/U4pjn18U9Nw?si=gvTiT00u1c74Kj-K'
                    controls={true}
                    width="100%"
                    height="100%"
                    autoplay={false}
                    light={true}
                    playing={false}
                  />
                </div>

                <div className="testimonial-card">
                  <ReactPlayer url='https://youtu.be/uRnnAHssKI0?si=k-Rlc8_8YsCF3F5B'
                    controls={true}
                    width="100%"
                    height="100%"
                    autoplay={false}
                    light={true}
                    playing={false}
                  />
                </div>

                <div className="testimonial-card">
                  <ReactPlayer url='https://youtu.be/aNKS-mFBDpg?si=TE3Yp87va-vVkX_1'
                    controls={true}
                    width="100%"
                    height="100%"
                    autoplay={false}
                    light={true}
                    playing={false}
                  />
                </div>

              </div>

              <div className='carousel-body'>
                <div className="testimonial-card">
                  <ReactPlayer url='https://youtu.be/WPwBThfPPAg?si=2kRrqRc2j6a8Ws4M'
                    controls={true}
                    width="100%"
                    height="100%"
                    autoplay={false}
                    light={true}
                    playing={false}
                  />
                </div>

                <div className="testimonial-card">
                  <ReactPlayer url='https://youtu.be/rr4obxllFfs?si=sOS4T-pe8ylJ61XH'
                    controls={true}
                    width="100%"
                    height="100%"
                    autoplay={false}
                    light={true}
                    playing={false}
                  />
                </div>
                <div className="testimonial-card">
                  <ReactPlayer url='https://youtu.be/kSBS4uo72Eg?si=ZtiHi_09_-W9Q7TH'
                    controls={true}
                    width="100%"
                    height="100%"
                    autoplay={false}
                    light={true}
                    playing={false}
                  />
                </div>
              </div>

              <div className='carousel-body'>
                <div className="testimonial-card">
                  <ReactPlayer url='https://youtu.be/pIaHPpx_1PU?si=Kjbui3kIZK53gLwC'
                    controls={true}
                    width="100%"
                    height="100%"
                    autoplay={false}
                    light={true}
                    playing={false}
                  />
                </div>

                <div className="testimonial-card">
                  <ReactPlayer url='https://youtu.be/fKgOd8kNEGY?si=MaWsBh491VDd-qun'
                    controls={true}
                    width="100%"
                    height="100%"
                    autoplay={false}
                    light={true}
                    playing={false}
                  />
                </div>

                <div className="testimonial-card">
                  <ReactPlayer url='https://youtu.be/mTtHPIxB4tw?si=ZeYOsP6mmQXHGyZh'
                    controls={true}
                    width="100%"
                    height="100%"
                    autoplay={false}
                    light={true}
                    playing={false}
                  />
                </div>

              </div>

            </Carousel>
          </div>
        </div>
      </div>
      {/* Videos ends */}

      <Footer />
    </div >
  )
}

export default Home
