import './Components/Sass/main.scss'
import './Components/Sass/media.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Components/Pages/Home'
import "animate.css/animate.min.css";
import About from './Components/Pages/About';
import Courses from './Components/Pages/Courses';
import OurTeam from './Components/Pages/OurTeam';
import Contact from './Components/Pages/Contact';
import Gallery from './Components/Pages/Gallery';
import TermsOfService from './Components/Pages/TermsOfService';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/courses' element={<Courses />} />
          <Route path='/our-team' element={<OurTeam />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/Terms-of-service' element={<TermsOfService />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
