import React from 'react'
import Navbar from '../Utilities/Navbar'
import Footer from '../Utilities/Footer'
import { useEffect } from 'react'
import Whatsapp from '../Utilities/Whatsapp';

const PrivacyPolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <div>
      <Navbar />
           <Whatsapp/>

      <div className="privacy-policy">
        <div className="heading" style={{ width: '100%' }}>
          <h3>privacy policy</h3>
        </div>
        <div className="privacy-policy-body">
          <p>We at Bliss School of Music are devoted to safeguarding your privacy and take that responsibility very seriously. When you visit our website, use our services, or sign up for one of our programs, we collect, use, and protect personal information about you. Please take some time to thoroughly read this policy to learn how we handle your data.
          </p>
          <div className="privacy-card">
            <ol>
              <li> <span>Data That We Gather</span>
                <p>In order to offer you our services and enhance your experience, we gather a variety of information. The kinds of information we may get include:</p>
                <ul>
                  <li><b>Personal Data:</b> When you sign up for one of our programs or get in touch with us, we might record personal data including your name, email address, phone number, etc.</li>
                  <li><b> Usage Data:</b> Information such as your IP address, browser type, operating system, and the pages you visit may be collected about how you use our website and services.</li>
                  <li><b>Cookies and Related Technologies:</b>   Similar to many other websites, we may use cookies and related technologies to gather data regarding your browsing habits. Your browser's settings will let you modify your cookie options.</li>
                </ul>
              </li>

              <li> <span>The Use of Your Information by Us</span>
                <p>We utilize the data we gather for a number of things, including but not restricted to</p>
                <ul>
                  <li><b>Providing Services:</b> Enrolling you in our programs and distributing educational materials.</li>
                  <li><b>Communication:</b> To respond to your inquiries, provide you the updates, and send you marketing materials (you can opt-out at any time).</li>
                  <li><b>Improving Services:</b> To better our website and services, we monitor usage trends, collect demographic data, and do other research.</li>
                  <li><b>Legal Compliance:</b> To uphold rights and settle differences in accordance with the law.</li>
                </ul>
              </li>

              <li> <span>Data Security</span>
                <p>We take the security of your data seriously and take the necessary precautions to keep it from being accessed, disclosed, altered, or destroyed by unauthorized parties. Please remember, however, that no form of electronic storage or communication via the Internet is 100% safe, and we cannot provide 100% security.</p>
              </li>

              <li> <span>Information Sharing</span>
                <p>We never trade, sell, or rent out your personal information to third parties. Only when necessary, we may share your information with dependable service partners who assist us in running our website and services. These service providers have a legal obligation to safeguard your data.</p>               
              </li>

              <li> <span>Hyperlinks to Other Websites</span>
                <p>Links to third-party websites or services may be found on our website. Please be aware that we have no control over these websites' privacy policies. We advise you to read the privacy statements for any websites operated by third parties.</p>               
              </li>

              <li> <span>Your Rights</span>
                <p>You have rights in relation to your personal data, including the ability to see, update, delete, or limit how it is processed. Please contact us using the details listed below if you want to use these rights or if you have any privacy-related worries.</p>
              </li>

              <li> <span>The privacy of children</span>
                <p>Young people under the age of 13 may use our services. We don't purposefully gather or ask for personal information from kids.</p>               
              </li>

              <li> <span>Revisions to this Privacy Statement</span>
                <p>For legal or regulatory reasons, as well as to reflect changes in our procedures, we may occasionally amend this privacy statement. Any significant modifications will be communicated to you by posting the revised policy on our website with a new effective date.</p>
               
              </li>

              <li> <span>Get in Touch</span>
                <p>Please get in touch with us at the following email address if you have any inquiries, requests, or concerns about this privacy statement or your personal information:</p>
               <p>Name: <b>Bliss School of Music</b></p>
               <p>Email Address: <b>blissschoolofmusic1@gmail.com.</b></p>
               <p>By using our website and services, you consent to the practices described in this Privacy Policy. We appreciate your confidence in Bliss School of Music to protect your privacy.</p>
              </li>
             
            </ol>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
