import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
    const [displays, SetDisplays] = useState("none");

    const show = () => {
        SetDisplays('block')
    }
    const hide = () => {
        SetDisplays('none')
    }
    return (
        <>
            <div className='header'>
                <div className="header-logo">
                    {/* <h1>Bliss School Of Music</h1> */}
                    <Link to={"/"}>  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510113/Bliss%20School%20Of%20Music/Logo/BSOM_transparent_ffnlvs.png" alt="logo" /></Link>

                </div>
                <div className="header-links">
                    <Link to={"/"}>Home</Link>
                    <Link to={"/about"}>About Us </Link>
                    <Link to={"/courses"}>Courses</Link>
                    <Link to={"/our-team"}>Our Team</Link>
                    <Link to={"/gallery"}>Gallery</Link>
                    <Link to={"/contact"}>Contact Us</Link>
                    <a href="https://wa.me/919818670338">
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510107/Bliss%20School%20Of%20Music/whatsapp_270830_mip08i.png" alt="" />
                    </a>
                </div>
                <div className="header-menu" onClick={show}>
                    <svg height="512" viewBox="0 0 20 20" width="512" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m3 5c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289h12c.2652 0 .5196.10536.7071.29289.1875.18754.2929.44189.2929.70711s-.1054.51957-.2929.70711c-.1875.18753-.4419.29289-.7071.29289h-12c-.26522 0-.51957-.10536-.70711-.29289-.18753-.18754-.29289-.44189-.29289-.70711zm0 5c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289h12c.2652 0 .5196.10536.7071.29289.1875.18754.2929.44189.2929.70711 0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929h-12c-.26522 0-.51957-.1054-.70711-.2929-.18753-.1875-.29289-.4419-.29289-.7071zm6 5c0-.2652.10536-.5196.29289-.7071.18754-.1875.44189-.2929.70711-.2929h6c.2652 0 .5196.1054.7071.2929s.2929.4419.2929.7071-.1054.5196-.2929.7071-.4419.2929-.7071.2929h-6c-.26522 0-.51957-.1054-.70711-.2929-.18753-.1875-.29289-.4419-.29289-.7071z" fill="rgb(0,0,0)" fill-rule="evenodd" /></svg>
                </div>
            </div>
            <div className="header-mobile" data-mdb-toggle="animation" data-mdb-animation-reset="true" data-mdb-animation="slide-in-right" style={{ display: displays }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" onClick={hide} /></svg>

                <div className="header-mobile-links">
                    <Link to={"/"}>Home</Link>
                    <Link to={"/about"}>About Us </Link>
                    <Link to={"/courses"}>Courses</Link>
                    <Link to={"/our-team"}>Our Team</Link>
                    <Link to={"/gallery"}>Gallery</Link>
                    <Link to={"/contact"}>Contact Us</Link>

                </div>
            </div>
        </>
    )
}

export default Navbar
