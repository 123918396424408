import React, { useEffect } from 'react'
import Navbar from '../Utilities/Navbar'
import Footer from '../Utilities/Footer'
import banner from '../Images/Courses/banner.png'
import { Link } from 'react-router-dom'
import Whatsapp from '../Utilities/Whatsapp';

const Courses = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <div>
            <Navbar />
            <Whatsapp/>

            <div className="courses-page">
                <div className="banner">
                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510112/Bliss%20School%20Of%20Music/Courses/banner_chz6un.png" alt="banner" />
                    <div className="banner-caption">
                        <h3><Link to={"/"}>Home</Link> / Our Courses</h3>
                    </div>
                </div>

                <div className="courses-body">
                    <div className="heading">
                        <h3>Courses we offer</h3>
                    </div>
                    <div className="courses-card">
                        <div className="courses-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510110/Bliss%20School%20Of%20Music/Courses/2_j8ypg1.png" alt="" />

                        </div>
                        <div className="courses-card-caption">
                            <h4>Piano Courses</h4>
                            <span>Piano Prowess</span>
                            <p>Welcome to our upright piano lessons, where you will immerse yourself in the enchanting world of piano melodies. Our lessons are designed to develop your piano skills, from beginner to master. Join us on a harmonic journey, where you will harmonize with ivory keys and create timeless melodies.</p>
                        </div>
                    </div>

                    <div className="courses-card">
                        <div className="courses-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696569659/Bliss%20School%20Of%20Music/Courses/cg_imepoo.png" alt="" />
                        </div>
                        <div className="courses-card-caption">
                            <h4>Classical Guitar Courses</h4>
                            <span>Play elegantly</span>
                            <p>Our classical guitar lessons are designed for those who want to play with historical and traditional strings. Immerse yourself in the enchanting world of classical guitar music and let your fingers dance across the strings, creating harmonies that resonate through the ages.</p>
                        </div>
                    </div>

                    <div className="courses-card">
                        <div className="courses-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696569050/Bliss%20School%20Of%20Music/Courses/ag_vtyvyh.png" alt="" />

                        </div>
                        <div className="courses-card-caption">
                            <h4>Acoustic Guitar Courses</h4>
                            <span>Embrace the serenity in sound</span>
                            <p>In our acoustic guitar lessons, you will embark on a melodic adventure, embracing the raw and soulful sound of the acoustic strings. Whether you're a beginner or a seasoned player, our lessons will improve your acoustic guitar playing skills, allowing you to create melodies that touch your soul.</p>
                        </div>
                    </div>

                    <div className="courses-card">
                        <div className="courses-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510111/Bliss%20School%20Of%20Music/Courses/3_yxfzua.png" alt="" />
                        </div>
                        <div className="courses-card-caption">
                            <h4>Vocal Courses</h4>
                            <span>Enhance your vocal symphony</span>
                            <p>Unleash the power of your voice in our singing lessons. Our experienced instructors will guide you through the nuances of singing, helping you discover the full potential of your vocal range. Listen to the art of expression and let your voice tell stories through song.</p>
                        </div>
                    </div>

                    <div className="courses-card">
                        <div className="courses-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510112/Bliss%20School%20Of%20Music/Courses/4_qnt2cd.png" alt="" />

                        </div>
                        <div className="courses-card-caption">
                            <h4>Drum Courses</h4>
                            <span>Rhythmic resonance</span>
                            <p>Join our drum lessons to discover the pulse of music. Feel the rhythm coursing through your veins as you learn how to create captivating rhythms. Whether you're a beginner or an experienced percussionist, our lessons will help you find your rhythm.</p>
                        </div>
                    </div>

                    <div className="courses-card">
                        <div className="courses-img">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510110/Bliss%20School%20Of%20Music/Courses/1_a2nqb9.png" alt="" />

                        </div>
                        <div className="courses-card-caption">
                            <h4>Keyboard Courses</h4>
                            <span>The key to musical versatility</span>
                            <p>Explore the versatile world of music in our keyboard lessons. The keyboard is a versatile instrument capable of playing melodies of many different genres. Immerse yourself in the world of keyboards, where you'll learn how to create melodies that span the entire musical spectrum.</p>
                        </div>
                    </div>
                </div>

                {/* pricing */}
                <div className="pricing" id='pricing'>
                    <div className="heading">
                        <h3>Fee Structure</h3>
                    </div>
                    <div class="pricing-table">
                        <div class="ptable-item">
                            <div class="ptable-single">
                                <div class="ptable-header">
                                    <div class="ptable-title">
                                        <h2>Piano / Keyboard</h2>
                                    </div>
                                    <div class="ptable-price">
                                        <h2><small>&#x20B9;</small>10,500<span>/ 3M</span></h2>
                                    </div>
                                </div>
                                <div class="ptable-body">
                                    <div class="ptable-description">
                                        <ul>
                                            <li>13 Individual practical classes</li>
                                            <li>13 Theory classes in a group</li>
                                            <li>Twice in a week</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="ptable-footer">
                                    <div class="ptable-action">
                                        <Link to={"/contact/#contact"}>Apply Now </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ptable-item">
                            <div class="ptable-single">
                                <div class="ptable-header">
                                    <div class="ptable-title">
                                        <h2>Guitar </h2>
                                    </div>
                                    <div class="ptable-price">
                                        <h2><small>&#x20B9;</small>10,500<span>/ 3M</span></h2>
                                    </div>
                                </div>
                                <div class="ptable-body">
                                    <div class="ptable-description">
                                        <ul>
                                            <li>13 Individual practical classes</li>
                                            <li>13 Theory classes in a group</li>
                                            <li>Twice in a week</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="ptable-footer">
                                    <div class="ptable-action">
                                        <Link to={"/contact/#contact"}>Apply Now </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ptable-item">
                            <div class="ptable-single">
                                <div class="ptable-header">
                                    <div class="ptable-title">
                                        <h2>Western vocal</h2>
                                    </div>
                                    <div class="ptable-price">
                                        <h2><small>&#x20B9;</small>12,000<span>/ 3M</span></h2>
                                    </div>
                                </div>
                                <div class="ptable-body">
                                    <div class="ptable-description">
                                        <ul>
                                            <li>13 Individual practical classes</li>
                                            <li>Once in a week</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="ptable-footer">
                                    <div class="ptable-action">
                                        <Link to={"/contact/#contact"}>Apply Now </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ptable-item">
                            <div class="ptable-single">
                                <div class="ptable-header">
                                    <div class="ptable-title">
                                        <h2>Drums</h2>
                                    </div>
                                    <div class="ptable-price">
                                        <h2><small>&#x20B9;</small>12,000<span>/ 3M</span></h2>
                                    </div>
                                </div>
                                <div class="ptable-body">
                                    <div class="ptable-description">
                                        <ul>
                                            <li>13 Individual practical classes</li>
                                            <li>Once in a week</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="ptable-footer">
                                    <div class="ptable-action">
                                        <Link to={"/contact/#contact"}>Apply Now </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* pricing ends */}
            </div>
            <Footer />
        </div>
    )
}

export default Courses
