import React from 'react'

const Whatsapp = () => {
  return (
    <div>
       <a href="https://wa.me/919818670338" className='whatsapp'>
        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696510107/Bliss%20School%20Of%20Music/whatsapp_270830_mip08i.png" alt="" />
      </a>
    </div>
  )
}

export default Whatsapp
